<template>
  <div class="number-card flex flex-direction-row justify-content-center">
    <dv-border-box-12 v-for="(item, index) in digitalFlopData" :key="index"
    class="number-card-item" :color="['#244b86','#3a59fe']">
        <dv-digital-flop :config="item.number" class="number"/>
        <div class="title">{{ item.title }}</div>
    </dv-border-box-12>
  </div>
<!-- backgroundColor="#2c3e50cc" -->
</template>

<script>
export default {
  name: 'NumberCard',
  data () {
    return {
      // digitalFlopData: []
    };
  },
  mounted () {
    // this.load();
  },
  computed: {
    digitalFlopData () {
      var data = this.$store.state.data.numberCards;
      return [
        {
          title: '今日销售房间',
          number: {
            number: [data.selledToday],
            content: '{nt} 间',
            style: {
              fontSize: 32,
              fill: '#ffffff'
            }
          }
        }, {
          title: '今日入住人数',
          number: {
            number: [data.checkinToday],
            content: '{nt} 人 ',
            style: {
              fontSize: 32,
              fill: '#ffffff'
            }
          }
        }, {
          title: '预计预抵人数',
          number: {
            number: [data.maybeCheckin],
            content: '{nt} 人',
            style: {
              fontSize: 32,
              fill: '#ffffff'
            }
          }
        }, {
          title: '明日入住人数',
          number: {
            number: [data.checkinTomorrow],
            content: '{nt}人',
            style: {
              fontSize: 32,
              fill: '#ffffff'
            }
          }
        }, {
          title: '房屋数量',
          number: {
            number: [data.roomCount],
            content: '{nt} 间',
            style: {
              fontSize: 32,
              fill: '#ffffff'
            }
          }
        }, {
          title: '门店数量',
          number: {
            number: [data.storeCount],
            content: '{nt} 个',
            style: {
              fontSize: 32,
              fill: '#ffffff'
            }
          }
        }
      ];
    }
  },
  methods: {

    load () {
      this.digitalFlopData = [
        {
          title: '今日销售房间',
          number: {
            number: [28],
            content: '{nt} 间',
            style: {
              fontSize: 32,
              fill: '#ffffff'
            }
          }
        }, {
          title: '今日入住人数',
          number: {
            number: [34],
            content: '{nt} 人 ',
            style: {
              fontSize: 32,
              fill: '#ffffff'
            }
          }
        }, {
          title: '预计预抵人数',
          number: {
            number: [4],
            content: '{nt} 人',
            style: {
              fontSize: 32,
              fill: '#ffffff'
            }
          }
        }, {
          title: '明日入住人数',
          number: {
            number: [28],
            content: '{nt}人',
            style: {
              fontSize: 32,
              fill: '#ffffff'
            }
          }
        }, {
          title: '房屋数量',
          number: {
            number: [32],
            content: '{nt} 间',
            style: {
              fontSize: 32,
              fill: '#ffffff'
            }
          }
        }, {
          title: '门店数量',
          number: {
            number: [4],
            content: '{nt} 个',
            style: {
              fontSize: 32,
              fill: '#ffffff'
            }
          }
        }
      ];
    }
  }

};
</script>

<style lang="less">
.border-box-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*background-color: #2c3e5055;*/
}
.number-card{
  position: relative;
  width:100%;
  height: 100%;

  .number-card-item:first-child{
    margin-right: 25px;
    margin-left: 10px;
  }
  .number-card-item:last-child{
    margin-right: 10px;
    margin-left: 25px;
  }
  .number-card-item{
    margin-left: 25px;
    margin-right: 25px ;
    width:15%;
      .number{
        width: 80%;
        height: 50%;
      }
      .title{
        color: #DCC37C;
      }
  }
}
</style>
