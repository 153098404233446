<template>
<!--  <div id="roomRate" class="roomRate"></div>-->
  <dv-charts :option="option" />
</template>

<script>

export default {
  name: "RoomRateLine",
  data () {
    return {
      option: {}
    };
  },
  mounted () {
    this.setTimer();
  },
  methods: {
    setTimer () {
      const self = this;
      this.$store.dispatch('data/checkinLine')
        .then(function (res) {
          self.setData(res);
          setTimeout(() => {
            self.setTimer();
          }, 1000 * 60 * 8);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setData (data) {
      this.option = {
        title: {
          text: '入住人数趋势',
          style: {
            fill: '#fff',
            fontSize: 17,
            fontWeight: 'bold',
            textAlign: 'center',
            textBaseline: 'bottom'
          }
        },
        color: ['#fb7293'],
        legend: {
          data: ['入住人数'],
          textStyle: {
            fontFamily: 'Arial',
            fontSize: 13,
            fill: '#fff'
          }
        },
        xAxis: {
          name: '日期',
          data: ["12.07", "12.08", "12.09", "12.10", "12.11", "12.12", "12.13"],
          nameTextStyle: {
            fill: '#fff',
            fontSize: 10
          },
          axisLabel: {
            style: {
              fill: '#fff'
            }
          },
          axisLine: {
            style: {
              stroke: '#26467c',
              lineWidth: 1
            }
          }
        },
        yAxis: {
          name: '数量（人）',
          data: 'value',
          min: 0,
          interval: 10,
          nameTextStyle: {
            fill: '#fff',
            fontSize: 10
          },
          axisLabel: {
            style: {
              fill: '#fff'
            }
          },
          splitLine: {
            show: true,
            style: {
              stroke: '#26467c',
              lineWidth: 1
            }
          },
          axisLine: {
            style: {
              stroke: '#26467c',
              lineWidth: 1
            }
          }
        },
        series: [
          {
            name: '入住人数',
            data: [5, 6, 11, 17, 13, 16, 11],
            type: 'line',
            stack: 'a',
            lineStyle: {
              lineWidth: 2
            }
          }
        ]
      };
    }
  }
};
</script>

<style lang="less" scoped>

</style>
