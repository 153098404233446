<template>
  <dv-charts :option="option" />
</template>

<script>
export default {
  name: "LockGauge",
  data () {
    return {
      // option: {}
    };
  },
  mounted () {
    // this.load();
  },
  computed: {
    option () {
      var data = this.$store.state.data.numberCards;
      return {
        title: {
          text: '智能门锁安装率',
          style: {
            fill: '#fff'
          }
        },
        series: [
          {
            type: 'gauge',
            data: [{ name: 'itemA', value: data.lockRate }],
            center: ['50%', '55%'],
            axisLabel: {
              formatter: '{value}%',
              style: {
                fill: '#fff'
              }
            },
            axisTick: {
              style: {
                stroke: '#fff'
              }
            },
            animationCurve: 'easeInOutBack'
          }
        ]
      };
    }
  },
  methods: {
    load () {
      this.option = {
        title: {
          text: '智能门锁安装情况',
          style: {
            fill: '#fff'
          }
        },
        series: [
          {
            type: 'gauge',
            data: [{ name: 'itemA', value: 89 }],
            center: ['50%', '55%'],
            axisLabel: {
              formatter: '{value}%',
              style: {
                fill: '#fff'
              }
            },
            axisTick: {
              style: {
                stroke: '#fff'
              }
            },
            animationCurve: 'easeInOutBack'
          }
        ]
      };
    }
  }
};
</script>

<style lang="less" scoped>

</style>
