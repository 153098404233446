<template>
  <router-view />
</template>

<script>
export default {

};
</script>

<style>
  @import './assets/flex.css';
</style>
