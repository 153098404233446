import Vue from 'vue';
import App from './App.vue';
import Element from 'element-ui';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
import BaiduMap from 'vue-baidu-map';
import './icons';

Vue.use(Element, { size: 'medium' });
Vue.use(dataV);
Vue.use(BaiduMap, {
  ak: 'Kr4DXaohtH2y2LA6k7qsR9sMa3qTH7d0'
});
Vue.config.productionTip = false;
Vue.prototype.$store = store;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
