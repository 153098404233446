import api from '@/utils/request.js';

export default {
  numbers () {
    return api.get('/data/numbers');
  },
  locks () {
    return api.get('/data/lock/positions');
  },
  orders () {
    return api.get('/data/order/today');
  },
  realNames () {
    return api.get('/data/order/real_name');
  },
  lockRecords () {
    return api.get('/data/lock/record');
  },
  checkinLine () {
    return api.get('/data/order/checkin/line');
  }
};
