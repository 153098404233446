import axios from 'axios';
import cookie from '@/utils/cookie';
import config from '@/config/index';
import util from '@/utils/util';
import { Message } from 'element-ui';

/**
 * 创建实例
 */
function create () {
  const instance = axios.create({
    baseURL: config.server.root,
    withCredentials: true
  });
  beforRequst(instance);
  beforThen(instance);
  return instance;
}

function beforRequst (instance) {
  instance.interceptors.request.use(config => {
    if (!config.url.includes('login')) {
      console.debug(config.url);
      var token = cookie.token.get();
      if (token) {
        config.headers['X-Access-Token'] = token;
      } else {
        // window.location.href = '/#/login';
      }
    }
    return config;
  }, error => {
    // console.error('Request Config Error!');
    Message({
      message: error.msg || error.message,
      type: 'error',
      duration: 3000
    });
    return Promise.reject(error);
  });
}

function beforThen (instance) {
  instance.interceptors.response.use(function (response) {
    const { data } = response;
    if (data.code && data.code !== 0) {
      Message({
        message: data.msg || data.message,
        type: 'error',
        duration: 3000
      });
      return Promise.reject(new Error(data.msg || 'Error'));
    } else {
      // console.debug(11 + data);
      return data;
    }
  }, function (error) {
    if (error.response) {
      const code = error.response.status;
      if (code === 401) {
        cookie.user.remove();
        cookie.token.remove();
        window.location.href = '/#/login';
      } else {
        // console.error(error.message);
        Message({
          message: error.msg || error.message,
          type: 'error',
          duration: 3000
        });
        // Modal.error({title: 'API请求出错', content: error.message});
        return Promise.reject(error.message);
      }
    } else {
      // console.error(error.message);
      Message({
        message: error.msg || error.message,
        type: 'error',
        duration: 3000
      });
      // Modal.error({title: 'API请求出错', content: error.message});
      return Promise.reject(error.message);
    }
  });
}

const api = {
  get: function (url, params) {
    return create().get(url, { params: params });
  },
  post: function (url, params) {
    return create().post(url, params);
  },
  put: function (url, params) {
    return create().put(url, util.params);
  },
  delete: function (url, params) {
    return create().delete(url, { params: params });
  }
};

// 返回在vue模板中的调用接口
export default api;
