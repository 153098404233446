import Cookies from 'js-cookie';
import config from '@/config/index';

const DEFAULT_EXPIRES = 30; // 默认1天
const SessionKey = {
  AUTH_TOKEN: 'AUTH_TOKEN',
  CURRENT_USER: 'CURRENT_USER',
  Locking: 'LOCKING'
};

const cookie = {
  set: function (key, value) {
    Cookies.set(key, value);
  },
  setExpired: function (key, value) {
    Cookies.set(key, value, { expires: config.cookie.expires || DEFAULT_EXPIRES });
  },
  get: function (key) {
    return Cookies.get(key);
  },
  remove: function (key) {
    Cookies.remove(key);
  }
};

cookie.user = {
  set: function (user) {
    cookie.setExpired(SessionKey.CURRENT_USER, JSON.stringify(user));
  },
  get: function () {
    const userStr = cookie.get(SessionKey.CURRENT_USER);
    if (userStr) {
      return JSON.parse(userStr);
    }
  },
  remove: function () {
    cookie.remove(SessionKey.CURRENT_USER);
  }
};
cookie.token = {
  set: function (token) {
    cookie.setExpired(SessionKey.AUTH_TOKEN, token);
  },
  get: function () {
    return cookie.get(SessionKey.AUTH_TOKEN);
  },
  remove: function () {
    cookie.remove(SessionKey.AUTH_TOKEN);
  }
};

cookie.lock = {
  doLock: function () {
    cookie.set(SessionKey.Locking, true);
  },
  isLocked: function () {
    return cookie.get(SessionKey.Locking);
  },
  unLock: function () {
    cookie.remove(SessionKey.Locking);
  }
};

export default cookie;
