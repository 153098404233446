import dataApi from '@/api/data.js';

export default {
  namespaced: true,
  state: {
    numberCards: {
      selledToday: 0,
      checkinToday: 0,
      maybeCheckin: 0,
      checkinTomorrow: 0,
      roomCount: 0,
      storeCount: 0,
      lockRate: 0,
      checkinRate: 0
    },
    identityRate: { auth: 100, notAuth: 0 }
  },
  mutations: {
    setNumberCards (state, vm) {
      state.numberCards = vm;
    },
    setIdentityRate (state, vm) {
      state.identityRate = vm;
    }
  },
  actions: {
    read ({ commit, state }) {
      return new Promise((resolve, reject) => {
        dataApi.numbers().then(res => {
          // console.debug(res.data);
          commit('setNumberCards', res.data);
          resolve(res);
        });
      });
    },
    locks ({ commit, state }) {
      return new Promise((resolve, reject) => {
        dataApi.locks().then(res => {
          resolve(res);
        });
      });
    },
    orders ({ commit, state }) {
      return new Promise((resolve, reject) => {
        dataApi.orders().then(res => {
          var auth = 0;
          var total = 0;
          var configData = [];
          res.data.forEach((item) => {
            // 用于计算实名率
            total += item.personCount;
            auth += item.authedCount;
            // 拼表格数组
            var regionArray = item.regionName.split('|');
            var regionName = regionArray[regionArray.length - 1];
            var authStr;
            if (item.authedCount === 0) {
              authStr = '<span style="color:gray">未入住</span>';
            } else if (item.authedCount === item.personCount) {
              authStr = '<span style="color:limegreen">已实名</span>';
            } else {
              authStr = '<span style="color:orange">部分实名</span>';
            }
            var row = [regionName, item.storeName, item.roomName, item.personCount, authStr];
            configData.push(row);
          });

          commit('setIdentityRate', { auth: auth, notAuth: total - auth });
          resolve(configData);
        });
      });
    },
    realNames ({ commit, state }) {
      return new Promise((resolve, reject) => {
        dataApi.realNames().then(res => {
          var configData = [];
          res.data.forEach((item) => {
            var regionArray = item.regionName.split('|');
            var regionName = regionArray[regionArray.length - 1];
            var sex = item.sex === 1 ? '男' : '女';
            var row = [regionName, item.storeName, item.roomName, item.name, sex, item.idCard + '**', '已核验'];
            configData.push(row);
          });
          resolve(configData);
        });
      });
    },
    lockRecords ({ commit, state }) {
      return new Promise((resolve, reject) => {
        dataApi.lockRecords().then(res => {
          var configData = [];
          res.data.forEach((item) => {
            var regionArray = item.regionName.split('|');
            var regionName = regionArray[regionArray.length - 1];
            var type;
            if (item.openType === 'card') {
              type = "身份证";
            } else if (item.openType === 'face') {
              type = "人脸";
            } else if (item.openType === 'password') {
              type = "密码";
            } else {
              type = "其他";
            }
            var row = [regionName, item.storeName, item.roomName, item.name, item.time, type];
            configData.push(row);
          });
          resolve(configData);
        });
      });
    },
    checkinLine  ({ commit, state }) {
      return new Promise((resolve, reject) => {
        dataApi.checkinLine().then(res => {
          resolve(res.data);
        });
      });
    }
  }
};
