<template>
    <div class="top-header flex justify-content-center">
      <dv-decoration8 class="header-left-decoration" :color="['#DCC37C','#DCC37C']"/>
      <dv-decoration5 class="header-center-decoration" :color="['#DCC37C','#DCC37C']"/>
      <dv-decoration8 class="header-right-decoration"  :reverse="true" :color="['#DCC37C','#DCC37C']"/>
      <div class="header-title flex align-items-center"><img class="logo" src="../../assets/logo.png"/>{{ title || '苏安居智慧租住安全监控系统'}}</div>
    </div>
</template>

<script>
import config from '@/config/index.js';

export default {
  name: 'Header',
  data () {
    return {
      title: config.title
    };
  }

};
</script>

<style lang="less">
.top-header{
  position: relative;
  width:100%;
  height: 100px;
  flex-shrink: 0;
  /*background-color: #2c3e50cc;*/

  .header-center-decoration{
    width: 60%;
    height: 70px;
    margin-top: 30px;
  }
  .header-left-decoration, .header-right-decoration{
    width: 25%;
    height: 78px;
  }
  .header-title{
    position: absolute;
    font-size: 30px;
    color: #fff;
    left: 50%;
    top: 15px;
    transform: translateX(-50%);
    .logo{
      width: 32px;
      height: 32px;
      margin-right: 5px;
    }
  }
}
</style>
