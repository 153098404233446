import userApi from '@/api/user.js';

const user = {
  namespaced: true,
  state: {
    current: {}
  },
  mutations: {
    setCurrent (state, vm) {
      state.current = vm;
    }
  },
  actions: {
    loadUsers ({ commit }) {
      return new Promise((resolve, reject) => {
        userApi.getUsers().then(res => {
          commit('setUesrs', res.data);
          resolve();
        });
      });
    }
  }
};

export default user;
