<template>
  <dv-charts :option="option" class="identity-pip"/>
</template>

<script>
export default {
  name: "IdentityPip",
  data () {
    return {
      // option: {}
    };
  },
  computed: {
    option () {
      var rate = this.$store.state.data.identityRate;
      return {
        title: {
          text: '入住人实名率',
          style: {
            fill: '#fff'
          },
          offset: [0, -5]
        },
        color: ['#fb7293', "#37a2da"],
        series: [
          {
            type: 'pie',
            data: [
              { name: '未实名', value: rate.notAuth },
              { name: '已实名', value: rate.auth }
            ],
            outsideLabel: {
              show: false,
              formatter: '{name}'
            },
            insideLabel: {
              show: true,
              formatter: '{percent}%,{value}人'
            }
            // startAngle: Math.PI / 8
          }
        ]
      };
    }
  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.identity-pip{
  width: 100%;
}
</style>
