import config from '@/config/index';

const util = {};

/**
 * 判断数据类型
 * @param obj
 * @returns {string} string、array、object等
 */
util.toType = function (obj) {
  return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase();
};

/**
 * 过滤掉为null的参数值
 * @param o
 * @returns {*}
 */
util.filterNull = function (o) {
  for (var key in o) {
    if (o[key] === null) {
      delete o[key];
    }
    if (util.toType(o[key]) === 'string') {
      o[key] = o[key].trim();
    } else if (util.toType(o[key]) === 'object') {
      o[key] = util.filterNull(o[key]);
    } else if (util.toType(o[key]) === 'array') {
      o[key] = util.filterNull(o[key]);
    }
  }
  return o;
};

/**
 * 改写网站标题
 * @param title
 */
util.title = function (title) {
  title = title || config.title;
  window.document.title = title;
};

export default util;
