<template>
  <dv-full-screen-container
    class="data-view flex flex-direction-column justify-content-spaceBetween"
  >
    <baidu-map
      :center="center"
      :zoom="zoom"
      :scroll-wheel-zoom="false"
      :dragging="false"
      :double-click-zoom="false"
      :keyboard="false"
      class="bm-map"
      @ready="handler"
    >
      <!--      <bm-boundary name="苏州市" :fill-opacity="0.5" fill-color="red" :strokeWeight="1" strokeColor="blue"></bm-boundary>-->
      <bm-boundary
        name="苏州市姑苏区"
        :fill-opacity="0.8"
        fill-color="#37a2da"
        :strokeWeight="2"
        strokeColor="#3a59fe"
      ></bm-boundary>
      <!--      <bm-boundary name="苏州市吴中区" :fill-opacity="0.8" fill-color="#DCC37CFF" :strokeWeight="1" strokeColor="blue"></bm-boundary>-->
      <!--      <bm-boundary name="苏州市虎丘区" :fill-opacity="0.8" fill-color="#DCC37CFF" :strokeWeight="1" strokeColor="blue"></bm-boundary>-->
      <!--      <bm-boundary name="苏州市相城区" :fill-opacity="0.8" fill-color="#DCC37CFF" :strokeWeight="1" strokeColor="blue"></bm-boundary>-->
      <!--      <bm-boundary name="苏州市工业园区" :fill-opacity="0.8" fill-color="#DCC37CFF" :strokeWeight="1" strokeColor="blue"></bm-boundary>-->
      <!--      <bm-boundary name="苏州市吴江市" :fill-opacity="0.8" fill-color="#DCC37CFF" :strokeWeight="1" strokeColor="blue"></bm-boundary>-->
      <!--      <bm-boundary name="苏州市昆山市" :fill-opacity="0.8" fill-color="#DCC37CFF" :strokeWeight="1" strokeColor="blue"></bm-boundary>-->
      <!--      <bm-boundary name="苏州市常熟市" :fill-opacity="0.8" fill-color="#DCC37CFF" :strokeWeight="1" strokeColor="blue"></bm-boundary>-->
      <!--      <bm-boundary name="苏州市太仓市" :fill-opacity="0.8" fill-color="#DCC37CFF" :strokeWeight="1" strokeColor="blue"></bm-boundary>-->
      <!--      <bm-boundary name="苏州市张家港市" :fill-opacity="0.8" fill-color="#DCC37CFF" :strokeWeight="1" strokeColor="blue"></bm-boundary>-->
      <bml-marker-clusterer :average-center="false">
        <bm-marker
          v-for="(marker, index) in markers"
          :key="index"
          animation="BMAP_ANIMATION_BOUNCE"
          :position="{ lng: marker.longitude, lat: marker.latitude }"
        ></bm-marker>
      </bml-marker-clusterer>
    </baidu-map>

    <div class="filter">
    <div class="copyright"></div>

    <!-- 标题头 -->
    <Header style="height: 12%" />

    <!-- 数字 -->
    <NumberCard style="height: 12%" />

    <!-- 内容 -->
    <div class="content f1 flex flex-direction-row">
      <!-- 左 -->
      <div class="content-left flex flex-direction-column">
        <div
          class="checkin-info flex flex-direction-row justify-content-spaceBetween"
        >
          <CheckinList class="checkin-list" />
          <IdentityPip class="identity-pip" />
        </div>
        <IdentityList class="identity-list" />
      </div>
      <!-- 中 -->
      <div
        class="content-center f1 flex-direction-column justify-content-spaceAround"
      >
        <div class="center-map f1"></div>
        <div class="center-charts">
          <RoomRateLine class="room-line" />
        </div>
      </div>
      <!-- 右 -->
      <div class="content-right">
        <div
          class="right-charts flex flex-direction-row justify-content-center"
        >
          <LockGauge />
          <CheckinGauge />
        </div>
        <LockOpenList class="lock-list" />
      </div>
    </div>
    </div>
    
  </dv-full-screen-container>
</template>

<script>
// import myStyle from "./components/custom_map_config";
import { BmlMarkerClusterer } from "vue-baidu-map";
import Header from "./components/Header";
import NumberCard from "./components/NumberCard";
import CheckinList from "./components/CheckinList";
import IdentityList from "./components/IdentityList";
import LockOpenList from "./components/LockOpenList";
import LockGauge from "./components/LockGauge";
import CheckinGauge from "./components/CheckinGauge";
import RoomRateLine from "./components/RoomRateLine";
import IdentityPip from "./components/IdentityPip";

import cookie from "@/utils/cookie.js";
import util from "@/utils/util.js";
import config from "@/config/index.js";

export default {
  name: "Home",
  components: {
    BmlMarkerClusterer,
    IdentityPip,
    RoomRateLine,
    CheckinGauge,
    LockGauge,
    LockOpenList,
    IdentityList,
    CheckinList,
    NumberCard,
    Header
  },
  data () {
    return {
      center: "苏州市",
      zoom: 13,
      mapStyle: {
        styleJson: [
          {
            featureType: "land",
            elementType: "geometry",
            stylers: {
              visibility: "on",
              color: "#000b26"
            }
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: {
              visibility: "on",
              color: "#000f37"
            }
          },
          {
            featureType: "green",
            elementType: "geometry",
            stylers: {
              visibility: "on",
              color: "#001248"
            }
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: {
              color: "#12223dff"
            }
          },
          {
            featureType: "poilabel",
            elementType: "labels",
            stylers: {
              visibility: "on"
            }
          },
          {
            featureType: "districtlabel",
            elementType: "labels",
            stylers: {
              visibility: "off"
            }
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: {
              visibility: "on"
            }
          },
          {
            featureType: "road",
            elementType: "labels",
            stylers: {
              visibility: "off"
            }
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: {
              color: "#ffffff00"
            }
          },
          {
            featureType: "poilabel",
            elementType: "labels.icon",
            stylers: {
              visibility: "off"
            }
          },
          {
            featureType: "poilabel",
            elementType: "labels.text.fill",
            stylers: {
              color: "#1E6E63ff"
            }
          },
          {
            featureType: "poilabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffff00"
            }
          },
          {
            featureType: "districtlabel",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#ffffffff"
            }
          }
        ]
      },
      markers: [],
      markerStyle: [
        {
          url: this.iconUrl,
          size: { width: 72, height: 72 },
          textColor: "white",
          textSize: 28
        }
      ],
      iconUrl:
        "http://api.map.baidu.com/library/TextIconOverlay/1.2/src/images/m3.png"
    };
  },
  created () {
    if (!cookie.token.get()) {
      this.$router.push({ path: "/login" });
    } else {
      util.title(config.title);
      this.setTimer();
      this.loadLockPositionMarkers();
    }
  },
  mounted () {},
  methods: {
    handler ({ BMap, map }) {
      // const mapStyle = { style: 'midnight' }
      // map.setMapStyle(mapStyle)
    },
    setTimer () {
      // let timer;
      const self = this;
      this.$store
        .dispatch("data/read")
        .then(function (res) {
          if (res.success) {
            setTimeout(() => {
              self.setTimer();
            }, 1000 * 60 * 5);
          } else {
            console.log(res.msg);
            // clearTimeout(timer); // 清理定时任务
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadLockPositionMarkers () {
      const self = this;
      this.$store.dispatch("data/locks").then(res => {
        console.log(res);
        self.markers = res.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.filter{
  background-color: #000000ce;
  height: 100%;
  width: 100%;
  position: relative;
}
.data-view {
  padding: 0;
  margin: 0;
  /*overflow: hidden;*/

}

.bm-map {
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.copyright {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 50px;
  left: 0;
  bottom: 0;
  background-color: #000b26ff;
}

.content {
  height: 76%;

  .content-left {
    width: 38%;
    height: 100%;
    padding-left: 10px;

    .checkin-info {
      height: 40%;

      .checkin-list {
        width: 65%;
        height: 100%;
        margin-top: 5%;
      }

      .identity-pip {
        width: 35%;
        margin-top: 10%;
      }
    }

    .identity-list {
      height: 50%;
      margin-top: 6.5%;
    }
  }

  .content-right {
    width: 30%;
    height: 100%;
    padding-right: 10px;

    .right-charts {
      height: 35%;
      margin-top: 15%;
    }

    .lock-list {
      height: 50%;
    }
  }

  .content-center {
    height: 95%;

    .center-map {
      height: 70%;
    }

    .center-charts {
      height: 30%;
      margin-left: 10px;
      margin-right: 10px;
      border-radius: 10px;
      background-color: #26467caa;

      .room-line {
      }
    }
  }
}
</style>
