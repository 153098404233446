<template>
    <div class="lock-open-card">
      <dv-border-box-11 :color="['#3a59fe','#26467c']" title="实时开锁进入情况"
      class="list-box">
        <dv-scroll-board :config="config" class="list-board f1" />
      </dv-border-box-11>
    </div>
</template>
<!-- backgroundColor="#2c3e50cc" -->
<script>
export default {
  name: 'LockOpenList',
  data () {
    return {
      config: {}
    };
  },
  mounted () {
    this.setTimer();
  },
  methods: {
    setTimer () {
      const self = this;
      this.$store.dispatch('data/lockRecords')
        .then(function (res) {
          self.setData(res);
          setTimeout(() => {
            self.setTimer();
          }, 1000 * 60 * 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setData (data) {
      this.config = {
        headerBGC: '',
        oddRowBGC: '',
        evenRowBGC: '',
        rowNum: 8,
        waitTime: 5000,
        header: [
          '<span style="color:#DCC37C;">区域</span>',
          '<span style="color:#DCC37C;">店名</span>',
          '<span style="color:#DCC37C;">房间</span>',
          '<span style="color:#DCC37C;">姓名</span>',
          '<span style="color:#DCC37C;">开锁时间</span>',
          '<span style="color:#DCC37C;">开锁方式</span>'
        ],
        columnWidth: [90, 100, 80, 80, 100],
        data: data,
        align: ['left']
      };
    }
  }
};
</script>

<style lang="less" scoped>
.border-box-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lock-open-card{
  position: relative;
  width: 100%;
  height: 100%;

  .list-box{
    padding-top: 50px;
    .list-board{
      width: 95%;
      height: 95%;
    }
  }
}
</style>
