
/**
 * @description token在Cookie中存储的天数，默认1天
 */
const cookie = {
  expires: 1
};
const server = {
  // root: 'http://192.168.2.22:8004'
  // root: 'http://dataapi.jixiangwulian.net'
  root: 'http://data.api.suanju.net'
};

const title = '苏安居流动人口智慧预警系统';

export default {
  cookie,
  server,
  title
};
